import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages, Flex, FlexContent, TitleServices, ListServices, BigTitleSmallPages, BoxImages} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"

import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"


import SEO from "../components/seo"

const poloznictwoirozrod= () => {
  return (

    <>
    <ThemeProvider theme={theme}>
    <SEO 
   title="Położnictwo i rozród"
   description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący: inseminację, cesarskie ciecie, prowadzenie suk w ciąży. 
    Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
    keywords="Pogotowie weterynaryjne kalisz 24h, poród zwierząt"
    />
    <Navbar />
    <BigTitleSmallPages style={{justifyContent: 'center',textAlign:'center'}}
         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Położnictwo i rozród</BigTitleSmallPages>  
         
         <FlexContent>
          <UslugiPages />
 <Flex>
<BoxImages>
 <StaticImage
    src="../images/rozrod.jpeg"
    alt="Rozród"
    width={800}
    layout="constrained"
    placeholder="blurred"
    className='staticimagepages'
    />
</BoxImages>
    <BoxTextPages>

<TitleServices>Świadczymy usługi z zakresu:</TitleServices>
<ListServices> USG narządu rodnego</ListServices>
<ListServices> Badania cytologiczne
</ListServices>
<ListServices> Leczenie niepłodności
</ListServices>
<ListServices>Prowadzenie suk w ciąży i opieka po porodzie nad szczeniętami
</ListServices>
<ListServices> Inseminacja</ListServices>
<ListServices> Leczenie zachowawcze i chirurgiczne narządu rodnego - ropomacicza</ListServices>
<ListServices> Zabiegi cesarskiego cięcia</ListServices>



    </BoxTextPages>

 
    
</Flex>
</FlexContent>
<Footer />
</ThemeProvider>
    </>
  )
}


export default poloznictwoirozrod
